<template>
  <div class="historyContainer" ref="historyContainer">
    <v-data-table :headers="fields" :items="items" :fields="fields" :loading="loading" :items-length="totalElements" density="compact">
      <template v-slot:[`item.datetime`]="{ item }">{{ $formatChDateTime(item.datetime) }}</template>
      <template v-slot:[`item.info`]="{ item }"><span class="min-width" v-html="formatInfo(item.info)"></span></template>

      <template v-slot:[`item.username`]="{ item }">
        <template v-if="$privileges.has({ path: '/applicationUsers', permission: 'read' }) && typeof item.applicationUserId == 'number'">
          <router-link
            :to="{
              name: 'applicationUsers_edit',
              params: { id: item.applicationUserId }
            }"
          >
            {{ item.username }}
          </router-link>
        </template>
        <template v-else>
          {{ item.username }}
        </template>
      </template>
      <template v-slot:[`item.naturalKey`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <span v-bind="props"> {{ item.naturalKey.substring(0, 34) }}</span>
          </template>
          <span> {{ item.naturalKey }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-btn id="changes" color="primary" class="shadow-none" @click="getProtocol()" :loading="downloading"><span v-html="$t('changes')" /></v-btn>
  </div>
</template>

<script lang="ts">
import { fileCreator, showError } from '@/services/axios'
import { apiURL, emitter } from '@/main'
import { DTSHeader } from '@/services/BackendService'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'history',
  props: {
    url: { type: String, required: true },
    itemperpage: { type: Number, required: false, default: 10 }
  },
  expose: ['loadHistory'],
  data() {
    return {
      items: [],
      loading: false,
      downloading: false,
      totalElements: 0,
      fields: <DTSHeader[]>[
        // Benutzer
        {
          title: this.$t('history_user'),
          key: 'username',
          sortable: false,
          align: 'left',
          width: '15%'
        },
        // Datum/Zeit
        {
          title: this.$t('history_date_time'),
          key: 'datetime',
          sortable: false,
          width: '10%'
        },
        // Operation
        {
          title: this.$t('history_revision_type'),
          key: 'revisionType',
          sortable: false,
          width: '10%'
        },
        // Entität
        {
          title: this.$t('history_entity_name'),
          key: 'entityName',
          sortable: false,
          width: '15%'
        },
        // ID
        // {
        //   title: this.$t('history_primary_key'),
        //   key: 'primaryKey',
        //   sortable: false
        // },
        // Natürlicher Schlüssel
        {
          title: this.$t('history_natural_key'),
          key: 'naturalKey',
          sortable: false,
          width: '10%'
        },
        // Änderungen
        {
          title: this.$t('history_revision_info'),
          key: 'info',
          sortable: false,
          width: '40%'
        }
      ]
    }
  },
  methods: {
    async loadHistory() {
      this.loading = true
      try {
        const result = await this.axios.get(apiURL + this.url + '/history', {
          headers: { 'Content-Type': 'application/json' }
        })

        this.items = result.data

        this.totalElements = this.items.length
      } catch (e) {
        console.error(e)
        this.items = [
          {
            revisionNo: 1,
            revisionType: 'No history found?',
            datetime: null,
            username: 'system',
            entityName: '-',
            primaryKey: '-',
            naturalKey: '-',
            info: null,
            applicationUserId: 1
          }
        ]
      } finally {
        this.loading = false
      }
    },
    formatInfo(info) {
      if (!info) return ''
      return info.replace(/\n/g, '<br>')
    },
    async getProtocol() {
      this.downloading = true
      try {
        const response = await this.axios.get(apiURL + this.url + '/history', {
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'protocol.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    }
  },
  watch: {
    async url() {
      await this.loadHistory()
    }
  },
  async mounted() {
    emitter.on('loadHistory', this.loadHistory)
    await this.loadHistory()
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.historyContainer {
  margin-bottom: 3rem;
}

.list-group {
  text-align: left;
}

.flex-items {
  display: flex;
  flex-flow: row;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.delete-remark:hover {
  cursor: pointer;
}

.position-left {
  float: right;
}

.position-right {
  float: right;
}

.margin-left {
  margin-left: $spacer-xs;
}

.top-margin {
  margin-top: $spacer-md;
}

/*Table*/
.v-data-table-server {
  margin-top: 0 !important;
}

td .min-width {
  display: flex;
  //width: 400px !important;
}

.v-window-item .v-table {
  margin-top: 1rem;
}
</style>
